
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.button {
    display: block !important;
    border-radius: 0.5rem;
    @include handleAllBreakpoints("font-size", "textSize", "var(--global-sections-buttons-text-size, 18px)");
    @include handleAllBreakpoints("font-weight", "textWeight", "600");
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    @include handleAllBreakpoints("color", "textColor", "var(--global-sections-buttons-text-color, white)");
    @include handleAllBreakpoints(
        "background",
        "buttonBackgroundColor",
        "var(--global-sections-buttons-background-color, black)"
    );
    @include handleAllBreakpoints(
        "border-radius",
        "buttonBorderRadius",
        "var(--global-sections-buttons-border-radius, 4px)"
    );
    @include handleAllBreakpoints("border", "buttonBorder", "none");
    @include handleAllBreakpoints("box-shadow", "buttonBoxShadow", "none");
    @include handleAllBreakpoints("min-width", "minWidth", "unset");
    @include handleAllBreakpoints("padding", "buttonPadding", "1rem");
    @include handleAllBreakpoints("margin", "buttonMargin", "1rem auto");

    @media (min-width: 640px) {
        padding-left: 2.75rem;
        padding-right: 2.75rem;
    }

    &:hover {
        @include handleAllBreakpoints("color", "hoverTextColor", "var(--global-sections-buttons-text-color, white)");
        @include handleAllBreakpoints(
            "background",
            "hoverButtonBackgroundColor",
            "var(--global-sections-buttons-background-color, black)"
        );
        @include handleAllBreakpoints("border", "hoverButtonBorder", "var(--global-sections-buttons-border, none)");
        transform: translateY(-0.5rem);
    }
}
